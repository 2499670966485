import { Customer } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import useFetchCustomer from '~/src/hooks/customer/useFetchCustomer'
import { useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { updateCustomer } from '~/src/services/CustomerService'
import { CUSTOMER_STATE } from '~/src/stores/customer'

interface ReturnsType {
  update(customer: Customer): Promise<void>,
  error: string | null,
  loading: boolean,
}

const useUpdateCustomer = (): ReturnsType => {
  const { t } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const { fetch: fetchCustomer } = useFetchCustomer()
  const setCustomerState = useSetEoState(CUSTOMER_STATE)
  const [ error, setError ] = useState<string | null>(null)
  const [ loading, setLoading ] = useState<boolean>(false)

  const update = async (customer: Customer): Promise<void> => {
    setError(null)
    setLoading(true)

    try {
      await updateCustomer(customer.id, customer)

      enqueueSnackbar(t('customer.updated'), { variant: 'success' })
      const updatedCustomer: Customer | undefined = await fetchCustomer()

      updatedCustomer && setCustomerState((state: Customer | null) => ({
        ...state,
        ...updatedCustomer
      }))
    } catch {
      setError(t('errors.defaultError'))
    } finally {
      setLoading(false)
    }
  }

  return { update, error, loading }
}

export default useUpdateCustomer
